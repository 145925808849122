// src/components/Showcase.js
import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box, CardActions, Button, Link } from '@mui/material';

const Showcase = ({ image, title, description, bgColor, link }) => (
  <Card sx={{ maxWidth: 345, margin: '20px', textAlign: 'center' }}>
    <CardMedia
      component="img"
      height="140"
      image={image}
      alt={title}
      style={{ backgroundColor: bgColor, objectFit: 'contain', padding: '20px' }}
    />
    <CardContent>
      <Typography variant="h5" component="div" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {description}
      </Typography>
    </CardContent>
    <CardActions>
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Button component={Link} to={link} size="large" color="primary" variant="contained" sx={{ width: '100%' }}>
            Læs mere
            </Button>
        </Box>
    </CardActions>
  </Card>
);

export default Showcase;
