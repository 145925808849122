// src/components/ProductCard.js
import React from 'react';
import { Card, CardContent, CardActions, Button, Typography, Link, Box } from '@mui/material';

const ProductCard = ({ title, description, price }) => (
  <Card sx={{ maxWidth: 345, margin: '20px', textAlign: 'center' }}>
    <CardContent>
      <Typography variant="h5" component="div">
        {title}
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ margin: '10px 0' }}>
        {description}
      </Typography>
      <Typography variant="h6" component="div" color="primary" style={{ fontWeight: '900', fontSize: '2em' }}>
        {price}
      </Typography>
    </CardContent>
  </Card>
);

export default ProductCard;
