// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Container, Button, ThemeProvider, CssBaseline, Box, Grid } from '@mui/material';
import Theme from './Theme';
import ProductCard from './components/ProductCard';
import Showcase from './components/Showcase';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

const Home = () => (
  <Container sx={{ height: { xs: 'calc(100vh - 314px)', sm: 'calc(100vh - 64px)' }, display: 'flex', alignItems: 'center' }}>
    <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ textAlign: 'center', width: '100%' }}>
      <Grid item xs={12}>
        <Typography variant="h1" component="h1" gutterBottom sx={{ fontWeight: '900', fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem' } }}>
          AnLe Consulting
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" color="textSecondary" gutterBottom sx={{ fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } }}>
          Wordpress, Software udvikling og IT konsulent
        </Typography>
      </Grid>
      <img 
        src="/EmilAndreae.png" 
        alt="Emil Andreæ" 
        style={{ height: '250px', width: 'auto', position: 'fixed', bottom: '0'}} 
      />
    </Grid>
  </Container>
);

const Wordpress = () => (
  <Container sx={{ height: { xs: 'auto', md: 'calc(100vh - 64px)' }, display: 'flex', alignItems: { xs: 'flex-start', md: 'center' }, pt: { xs: 2, md: 0 } }}>
    <div style={{ textAlign: 'center', width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Box textAlign="center">
          <Typography variant="h4" component="h1" gutterBottom>
            Wordpress
          </Typography>
          <Typography variant="body1" gutterBottom>
            Vi kan hjælpe dig med alt fra opsætning til support.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            <ProductCard
              title="Wordpress Opsætning"
              description="Installation og opsætning af Wordpress på din server. Inkluderer tema og plugins."
              price="7500 kr."
            />
            <ProductCard
              title="Wordpress Support"
              description="Vi holder din Wordpress side opdateret og sikker. Inkluderer support."
              price="300 kr./mdr."
            />
          </Box>
          <Typography variant="caption" color="textSecondary" gutterBottom>
            Alle priser er ekskl. moms
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button
              component={Link}
              to="/kontakt"
              size="large"
              color="primary"
              variant="contained"
              sx={{  }}
            >
              Kontakt os
            </Button>

            {/* <Button
              component={Link}
              to="/wordpress-eksempler"
              size="large"
              color="secondary"
              variant="contained"
              sx={{ marginLeft: '10px' }}
            >
              Se eksempler
            </Button> */}
          </Box>
        </Box>
      </Box>
    </div>
  </Container>
);

const Udvikling = () => (
  <Container sx={{ height: { xs: 'auto', md: 'calc(100vh - 64px)' }, display: 'flex', alignItems: { xs: 'flex-start', md: 'center' }, pt: { xs: 2, md: 0 } }}>
    <div style={{ textAlign: 'center', width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Box textAlign="center">
          <Typography variant="h4" component="h1" gutterBottom>
            Software udvikling
          </Typography>
          <Typography variant="body1" gutterBottom>
            Vi kan hjælpe dig med at udvikle software til din virksomhed eller startup ide. Se tidligere projekter herunder.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            <Showcase
              image="https://skydivelog.dk/assets/images/plane.png"
              title="Skydive Log"
              bgColor="#87CEEB"
              link="https://skydivelog.dk/"
              description="Skydive Log er en webapp til at holde styr på dine faldskærmsspring."
            />
            <Showcase
              image="https://flowagent.nu/wp-content/uploads/2024/05/FlowAgent-logo-white.svg"
              bgColor="#6060FF"
              title="FlowAgent"
              link="https://flowagent.nu/"
              description="FlowAgent er en platform og et system, som kan optimere alle former for arbejdsgange."
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button
              component={Link}
              to="/kontakt"
              size="large"
              color="primary"
              variant="contained"
              sx={{  }}
            >
              Kontakt os
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  </Container>
);

const Kontakt = () => (
  <Container sx={{ height: 'calc(100vh - 64px)', display: 'flex', alignItems: 'center' }}>
    <div style={{ textAlign: 'center', width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Box textAlign="center">
          <Typography variant="h4" component="h1" gutterBottom>
            Kontakt
          </Typography>
          <Typography variant="body1" gutterBottom>
            AnLe Consulting er klar til at hjælpe dig med din næste opgave. Kontakt os for et uforpligtende tilbud.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          <Box display="flex" alignItems="center" m={2} justifyContent="center">
            <PhoneIcon color="action" />
              <Typography variant="h5" color="textSecondary" gutterBottom ml={1}>
                22 28 11 68
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <EmailIcon color="action" />
              <Typography variant="h5" color="textSecondary" gutterBottom ml={1}>
                info@anle.dk
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  </Container>
);

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Router>
        <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none' }}>
          <Toolbar>
            <Grid container alignItems="center">
              <Grid item xs={2} sm={6} md={4} sx={{ padding: { xs: 2, sm: 2 } }}>
                <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
                  <img 
                    src="/favicon-32x32.png" 
                    alt="AnLe Consulting" 
                    style={{ height: '32px', width: 'auto', maxWidth: '32px' }} 
                  />
                </Link>
              </Grid>
              <Grid item xs={10} sm={6} md={8} container justifyContent="flex-end">
                <Button color="primary" component={Link} to="/wordpress">Wordpress</Button>
                <Button color="primary" component={Link} to="/udvikling">Udvikling</Button>
                <Button color="primary" component={Link} to="/kontakt">Kontakt</Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/wordpress" element={<Wordpress />} />
          <Route path="/udvikling" element={<Udvikling />} />
          <Route path="/kontakt" element={<Kontakt />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
